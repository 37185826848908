import { SCRATCH_OFF_GAMES_MARQUEE_FAILURE, SCRATCH_OFF_GAMES_MARQUEE_SUCCESS } from '../constants'

const INITIAL_STATE = {
  data: [],
};

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SCRATCH_OFF_GAMES_MARQUEE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        type: action.type,
      };
    }
    case SCRATCH_OFF_GAMES_MARQUEE_FAILURE: {
      return {
        ...state,
        type: action.type,
      };
    }
    default:
      return state;
  }
}
