import { unionBy, sortBy } from "lodash"

import {
  LOAD_LIVE_SHOW_DATA_REQUEST,
  LOAD_LIVE_SHOW_DATA_SUCCESS,
  LOAD_LIVE_SHOW_DATA_FAILURE,
  LOAD_LIVE_SHOW_DATA_RANGE_REQUEST,
  LOAD_LIVE_SHOW_DATA_RANGE_SUCCESS,
  LOAD_LIVE_SHOW_DATA_RANGE_FAILURE,
  SEARCH_DRAW_REQUEST,
  SEARCH_DRAW_SUCCESS,
  SEARCH_DRAW_FAILURE,
  LOAD_NEXT_LIVE_DRAW_REQUEST,
  LOAD_NEXT_LIVE_DRAW_SUCCESS,
  LOAD_NEXT_LIVE_DRAW_FAILURE,
  RESET_SEARCH_NUMBER,
  RESET_LIVE_SHOW_STATE,
  GO_TO_LIVE_START,
  GO_TO_LIVE_END,
  SET_ACTIVE_DRAW_NUMBER,
} from "../constants"

export const INITIAL_STATE = {
  draws: [],
  loadedInitialData: false,
  initialDataError: false,
  savedDraws: null,
  savedDrawNumber: null,
  latestDraws: [],
  isLoadingMore: false,
  searchDrawNumber: null,
  isSearchError: false,
  errorStatus: null,
  nextDrawTimestamp: null,
  liveShowDrawNumber: null,
  changeRouteTimestamp: null,
}

function mergeAndSort(array1, array2, unionByProp, sortByProp) {
  const mergedArrays = unionBy(array1, array2, sortByProp)

  return sortBy(mergedArrays, unionByProp)
}

function checkForGaps(arr) {
  return arr.some((draw, index) => {
    const nextDraw = arr[index + 1]

    return nextDraw && draw.drawNumber + 1 !== nextDraw.drawNumber
  })
}

export default function liveShowReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_LIVE_SHOW_DATA_REQUEST: {
      return {
        ...state,
        initialDataError: false,
      }
    }

    case SET_ACTIVE_DRAW_NUMBER: {
      return {
        ...state,
        activeDrawNumber: action.payload,
      }
    }

    case LOAD_LIVE_SHOW_DATA_SUCCESS: {
      const { draws, nextDrawTimestamp } = action.payload

      const drawsSorted = sortBy(draws, "drawNumber")
      const liveShow = drawsSorted[draws.length - 2]
      const liveShowDrawNumber = liveShow.drawNumber

      return {
        ...state,
        loadedInitialData: true,
        draws: drawsSorted,
        latestDraws: drawsSorted,
        nextDrawTimestamp,
        liveShowDrawNumber,
        activeDrawNumber: liveShowDrawNumber,
      }
    }

    case LOAD_LIVE_SHOW_DATA_FAILURE: {
      return {
        ...state,
        loadedInitialData: false,
        initialDataError: true,
      }
    }

    case LOAD_LIVE_SHOW_DATA_RANGE_REQUEST: {
      const newDraws = action.payload

      const updatedDraws = mergeAndSort(
        newDraws,
        state.draws,
        "drawNumber",
        "drawNumber"
      )

      return {
        ...state,
        initialDataError: false,
        draws: updatedDraws,
        isLoadingMore: true,
      }
    }

    case LOAD_LIVE_SHOW_DATA_RANGE_SUCCESS: {
      const { draws: newDraws } = action.payload

      const updatedDraws = mergeAndSort(
        newDraws,
        state.draws,
        "drawNumber",
        "drawNumber"
      )

      return {
        ...state,
        loadedInitialData: true,
        draws: updatedDraws,
        isLoadingMore: false,
      }
    }

    case LOAD_LIVE_SHOW_DATA_RANGE_FAILURE: {
      const newDraws = action.payload

      const updatedDraws = mergeAndSort(
        newDraws,
        state.draws,
        "drawNumber",
        "drawNumber"
      )

      return {
        ...state,
        isLoadingMore: false,
        draws: updatedDraws,
      }
    }

    case SEARCH_DRAW_REQUEST: {
      const {
        requestedDrawNumbers,
        currentDrawNumber,
        searchDrawNumber,
      } = action.payload

      return {
        ...state,
        initialDataError: false,
        draws: requestedDrawNumbers,
        savedDraws: [...state.draws],
        savedDrawNumber: currentDrawNumber,
        isSearchError: false,
        errorStatus: null,
        searchDrawNumber,
      }
    }

    case SEARCH_DRAW_SUCCESS: {
      const { draws } = action.payload

      return {
        ...state,
        draws,
        savedDraws: null,
        savedDrawNumber: null,
        isSearchError: false,
        errorStatus: null,
      }
    }

    case SEARCH_DRAW_FAILURE: {
      return {
        ...state,
        draws: state.savedDraws,
        savedDraws: null,
        isSearchError: true,
        errorStatus: action.payload,
      }
    }

    case RESET_SEARCH_NUMBER: {
      return {
        ...state,
        searchDrawNumber: null,
      }
    }

    case LOAD_NEXT_LIVE_DRAW_REQUEST: {
      const { nextLiveDraw } = action.payload

      const newDraws = nextLiveDraw ? [nextLiveDraw] : []

      const updatedDraws = mergeAndSort(
        newDraws,
        state.draws,
        "drawNumber",
        "drawNumber"
      )

      return {
        ...state,
        draws: updatedDraws,
      }
    }

    case LOAD_NEXT_LIVE_DRAW_SUCCESS: {
      const { draws, nextDrawTimestamp } = action.payload

      const updatedDraws = mergeAndSort(
        draws,
        state.draws,
        "drawNumber",
        "drawNumber"
      )

      const liveShow = updatedDraws[updatedDraws.length - 2]
      const liveShowDrawNumber = liveShow.drawNumber

      const hasNoGaps = !checkForGaps(updatedDraws)

      return {
        ...state,
        draws: hasNoGaps ? updatedDraws : state.draws,
        latestDraws: draws,
        nextDrawTimestamp,
        liveShowDrawNumber,
      }
    }

    case LOAD_NEXT_LIVE_DRAW_FAILURE: {
      const newDraws = [action.payload]

      const updatedDraws = mergeAndSort(
        newDraws,
        state.draws,
        "drawNumber",
        "drawNumber"
      )

      const updatedLastDraws = mergeAndSort(
        newDraws,
        state.latestDraws,
        "drawNumber",
        "drawNumber"
      )

      const hasNoGaps = !checkForGaps(updatedDraws)

      return {
        ...state,
        draws: hasNoGaps ? updatedDraws : state.draws,
        latestDraws: updatedLastDraws,
      }
    }

    case GO_TO_LIVE_START: {
      const updatedDraws = mergeAndSort(
        state.latestDraws,
        state.draws,
        "drawNumber",
        "drawNumber"
      )

      return {
        ...state,
        draws: updatedDraws,
      }
    }

    case GO_TO_LIVE_END: {
      const hasNoGaps = !checkForGaps(state.draws)

      if (hasNoGaps) {
        return state
      }

      return {
        ...state,
        draws: [...state.latestDraws],
      }
    }

    case RESET_LIVE_SHOW_STATE: {
      return INITIAL_STATE
    }

    default:
      return state
  }
}
