import {
  BASIC_PAGE_IDS_SUCCESS,
  PAGE_CONTENT_SUCCESS,
  PAGE_DETAIL_CONTENT_SUCCESS,
  DYNAMICPAGE_CONTENT_SUCCESS,
  FOOTER_LINKS_SUCCESS,
  FOOTER_SOCIALS_SUCCESS,
  EMAIL_SUBSCRIBE_SUCCESS,
  EMAIL_SUBSCRIBE_FAILURE,
  BASIC_PAGE_SUCCESS,
  TICKET_SCAN_CONTENT_SUCCESS,
  FOOTER_COPY_SUCCESS,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTION_FAILURE,
  FETCH_UPDATE_TOPIC_SUCCESS,
  FETCH_UPDATE_TOPIC_FAILURE
} from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.app

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case BASIC_PAGE_SUCCESS: {
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.name]: action.data,
        },
      }
    }
    case FOOTER_LINKS_SUCCESS: {
      return {
        ...state,
        footer: {
          ...state.footer,
          links: action.payload,
        },
      }
    }
    case FOOTER_SOCIALS_SUCCESS: {
      return {
        ...state,
        footer: {
          ...state.footer,
          socials: action.payload,
        },
      }
    }
      
    case EMAIL_SUBSCRIBE_SUCCESS: {
      return {
        ...state,
        email: action.data,
        emailErr: {},
      }
    }
      
    case EMAIL_SUBSCRIBE_FAILURE: {
      return {
        ...state,
        emailErr: action.err,
        email: {},
      }
    }

    case FETCH_UPDATE_TOPIC_SUCCESS: {
      return {
        ...state,
        emailTopics: action.data,
      }
    }

    case FETCH_UPDATE_TOPIC_FAILURE: {
      return {
        ...state,
        emailTopicsErr: action.err,
      }
    }

    case BASIC_PAGE_IDS_SUCCESS: {
      return {
        ...state,
        pageIds: action.payload,
      }
    }
    case PAGE_CONTENT_SUCCESS: {
      return {
        ...state,
        contents: action.payload,
      }
    }
    case PAGE_DETAIL_CONTENT_SUCCESS: {
      return {
        ...state,
        page: {
          ...state.page,
          [action.payload.page]: action.payload.data[0],
        },
      }
    }
    case DYNAMICPAGE_CONTENT_SUCCESS: {
      return {
        ...state,
        dynamiccontents: action.payload,
      }
    }
    case FOOTER_COPY_SUCCESS: {
      return {
        ...state,
        footer: {
          ...state.footer,
          copy: action.payload,
        },
      }
    }
    default:
      return state
  }
}
