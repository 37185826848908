import { LOADED, LOADING, LOADING_FAILURE, LOADING_RESET } from "../constants";
import initialState from "../initialState";

export default function (state = initialState.loading, action = {}) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loadingItems: [...state.loadingItems, ...addItems(action)]
      };
    case LOADED:
      // Remove payload items from loading items
      return {
        ...state,
        loadedItems: [...state.loadedItems, ...addItems(action)],
        loadingItems: removePayloadItemsFromLoading(state, action),
      };
    case LOADING_FAILURE:
      // Remove payload items from loading items
      return {
        ...state,
        loadingItems: removePayloadItemsFromLoading(state, action),
      };
    case LOADING_RESET:
      return { ...state, loadingItems: [], loadedItems: [] };
    default:
      return state;
  }
}

/**
 * Creates an array of objects with the key and the language
 */
function addItems(action) {
  return action?.payload?.add?.map(item => {
    return {
      key: item,
      language: action?.language || null,
    }
  }) || [];
}

function removePayloadItemsFromLoading(state, action) {
  return state.loadingItems.filter(item => {
    const found = action.payload.remove.find(removeItem => item.key === removeItem);
    return !found;
  });
}