import { LOAD_TICKERS_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.tickers;

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_TICKERS_SUCCESS:
      return [...action.payload]
    default:
      return state
  }
}
