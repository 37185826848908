import { FEATURED_WINNERS_SUCCESS, RECENT_WINNERS_SUCCESS, WINNERS_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.winners

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FEATURED_WINNERS_SUCCESS:
    case RECENT_WINNERS_SUCCESS:
    case WINNERS_SUCCESS: {
      return {
        ...state,
        [action.payload.type]: action.payload.data
      }
    }
    default:
      return state
  }
}
