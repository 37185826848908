import wrapWithProvider from "./wrap-with-provider"

export const shouldUpdateScroll = ({routerProps: { location }, getSavedScrollPosition}) => {
    window.history.scrollRestoration = 'manual'
    const currentPosition = getSavedScrollPosition(location)
    window.scrollTo(...currentPosition)
    return false
}

export const wrapRootElement = wrapWithProvider
