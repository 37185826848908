import { SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAILURE } from "../constants"
import initialState from "../initialState"


const INITIAL_STATE = initialState.messageResult

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        contact: action.payload,
      }
    }
    case SEND_MESSAGE_FAILURE: {
      return {
        ...state,
        contact: action.payload,
      }
    }
    default:
      return state
  }
}
