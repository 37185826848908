import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Provider, useDispatch } from "react-redux"
import store from "./src/redux/createStore"
import { loadGames } from "./src/redux/actions/gameActions"
import { loadBasicPageIds } from "./src/redux/actions/appActions"
import { setCurrentLanguage } from "./src/redux/actions/languageSwitchAction"

const Wrapper = ({ children }) => {
  const dispatch = useDispatch()
  // Before you decide to put your action in here, please note this will make the API call
  // on every single page. In order to reduce page speed, please only load actions here when they're
  // necessary to load on every single page
  useEffect(() => {
    // Draws is kept in here because it's critical information on the website,
    // But later should be refactored so we don't need to load draw data on every page load
    dispatch(loadGames())
    dispatch(loadBasicPageIds())
    dispatch(setCurrentLanguage())
  }, [dispatch])
  return <Fragment>{children}</Fragment>
}

// eslint-disable-next-line react/display-name,react/prop-types

const WrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <Wrapper>{element}</Wrapper>
    </Provider>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node
}

WrapWithProvider.propTypes = {
  children: PropTypes.node,
  element: PropTypes.node
}

export default WrapWithProvider
