export function isLoadingOrLoaded(state, types) {
  const currentLanguage = state?.i18nReducer?.language;
  return types.some(type => {
    const keysFromLoading = state?.loading?.loadingItems?.filter(item => item.key === type);
    const keysFromLoaded = state?.loading?.loadedItems?.filter(item => item.key === type);
    // If keys aren't present in both, then return false
    if (!keysFromLoading?.length && !keysFromLoaded?.length) return false;
    // Keys are present in one of loading or loaded, check if the current language is last in the array
    return [currentLanguage, null].includes(keysFromLoading?.at(-1)?.language) || [currentLanguage, null].includes(keysFromLoaded?.at(-1)?.language);
  });
}

export function dispatchWithLoading(dispatch, addRemove, loadType, payload, state) {
  const currentLanguage = state?.i18nReducer?.language;
  const { add, remove } = addRemove || {};
  (add || []).forEach(type => {
    dispatch({
      type,
      ...(payload ? { payload } : {}),
      language: currentLanguage
    });
  });
  dispatch({
    type: loadType,
    payload: {
      add: add || [], remove: remove || [],
    },
    language: currentLanguage
  });
}