import { MARQUEE_FAILURE, MARQUEE_SUCCESS } from '../constants'

const INITIAL_STATE = {
	data: [],
};

// Might be good candiate to refactor into hook

export default function (state = INITIAL_STATE, action = {}) {
	switch (action.type) {
		case MARQUEE_SUCCESS: {
			return {
				...state,
				data: action.payload,
				type: action.type,
			};
		}
		case MARQUEE_FAILURE: {
			return {
				...state,
				type: action.type,
			};
		}
		default:
			return state;
	}
}
