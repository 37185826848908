import {
    TICKET_SCAN_CONTENT_SUCCESS,
    TICKET_SCAN_SCAN_RESULTS_SUCCESS,
    TICKET_SCAN_SCAN_RESULTS_FAILURE,
    TICKET_SCAN_SCAN_RESULTS_CMS_SUCCESS,
    TICKET_SCAN_SCAN_RESULTS_CMS_FAILURE,
    TICKET_SCAN_LP_CMS_SUCCESS,
    TOGGLE_RESULTS_LOADING,
    RESET_RESULTS_TEMPLATE,
    TICKET_SCAN_FUEL_GAUGE_SUCCESS
} from "../constants"
import initialState from "../initialState"

// Internal ticketScan may be different than this ticket scan
const INITIAL_STATE = initialState.ticketScan
  
export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case TICKET_SCAN_CONTENT_SUCCESS: {
      return {
        ...state,
        ticketScan: action.payload,
      }
    }
    case RESET_RESULTS_TEMPLATE: {
      return { ...state,
        resultsLoading: false,
        scanResultsCMS: null,
      }
    }
    case TOGGLE_RESULTS_LOADING: {
      return { ...state, 
        resultsLoading: action.payload,
      }
    }
    case TICKET_SCAN_SCAN_RESULTS_SUCCESS: {
      return {
        ...state,
        scanResults: action.payload.data
      }
    }
    case TICKET_SCAN_SCAN_RESULTS_CMS_SUCCESS: {
      return {
        ...state,
        scanResultsCMS: action.payload,
      }
    }
    case TICKET_SCAN_SCAN_RESULTS_CMS_FAILURE: {
      return {
        ...state,
        scanResultsCMS: action.payload
      }
    }
    case TICKET_SCAN_LP_CMS_SUCCESS: {
      return {
        ...state,
        ticketScanLpCMS: action.payload,
      }
    }
    case TICKET_SCAN_FUEL_GAUGE_SUCCESS: {
      return {
        ...state,
        fuelCMS: action.payload.data
      }
    }
    default:
      return state
  }
}

