export const initialState = {
  language: "en",
  languages: [],
}

const i18nReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return { ...state, language: action.payload, languages: [...state.languages, action.payload] }
    default:
      return state
  }
}

export default i18nReducer;