import { WHERE_TO_WATCH_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.whereToWatch

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case WHERE_TO_WATCH_SUCCESS: {
      return [...action.payload]
    }
    default:
      return state
  }
}
