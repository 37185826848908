import { PRIZE_CLAIM_SUCCESS } from '../constants'

const INITIAL_STATE = [];

export default function(state = INITIAL_STATE, action = {}) {
	switch(action.type) {
		case PRIZE_CLAIM_SUCCESS: {
			return action.payload;
		}
		default:
			return state;
	}
}
