import {
  LOAD_TOKEN_SUCCESS,
  LOAD_USER_SUCCESS,
  SET_REDIRECT
} from "../constants"

import initialState from "../initialState"

const INITIAL_STATE = initialState.sso

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_TOKEN_SUCCESS:
      return { ...state, ...action.payload }
    case LOAD_USER_SUCCESS:
      return { ...state, ...action.payload }
    case SET_REDIRECT:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
