import {
	PROMOTIONS_SUCCESS,
} from '../constants'
import initialState from '../initialState'

const INITIAL_STATE = initialState.promotions

export default function(state = INITIAL_STATE, action = {}) {
	switch(action.type) {
		case PROMOTIONS_SUCCESS: {
			return [
				...action.payload,
			]
    }
		default:
			return state;
	}
}
