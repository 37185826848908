import { WINNER_SUCCESS } from "../constants"

const INITIAL_STATE = {}

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case WINNER_SUCCESS: {
      return {
        ...state,
        ...action.payload.data
      }
    }
    default:
      return state
  }
}
