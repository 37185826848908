import initialState from "../initialState"
const INITIAL_STATE = initialState.contactFormReducer
export default  function (state = INITIAL_STATE, action = {}) {
    switch (action.type) {
      case 'CONTACT_FORM_REQUEST':
        return { ...state, isSubmitting: true, success: false, successMessage:null, error: null };
      case 'CONTACT_FORM_SUCCESS':
        return { ...state, success: true, isSubmitting: false, successMessage:action.payload, error: null };
      case 'CONTACT_FORM_ERROR':
        return { ...state, isSubmitting: false, success: false, successMessage:null, error: action.payload };
      default:
        return state;
    }
  };