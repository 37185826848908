import {
  PRIZE_CLAIM_CENTER_REQUEST,
  PRIZE_CLAIM_CENTER_SUCCESS,
} from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.prizeClaimCenter

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case PRIZE_CLAIM_CENTER_REQUEST: {
      return []
    }
    case PRIZE_CLAIM_CENTER_SUCCESS: {
      return [
        ...action.payload.data
      ]
    }
    default:
      return state
  }
}
