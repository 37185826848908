import { INTEGRITY_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.integrity

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case INTEGRITY_SUCCESS: {
      return {
        security: action.payload.find((page) => page.alias === '/integrity-security'),
        trust: action.payload.find((page) => page.alias === '/integrity-trust')
      }
    }
    default:
      return state
  }
}