import { combineReducers } from "redux"
import games from "./gameReducer"
import marquees from "./marqueeReducer"
import scratchOffGamesMarquees from "./scratchOffGamesMarqueesReducer";
import categories from "./categoryReducer"
import promotions from "./promotionsReducer"
import events from "./eventsReducer"
import secondChance from "./secondChanceReducer"
import winners from "./winnersReducer"
import currentWinner from "./winnerReducer"
import scratchOffGame from "./scratchOffGameReducer"
import scratchOffGameDetail from "./scratchOffGameDetailReducer"
import scratchOffGamesReport from "./scratchOffGamesReportReducer"
import cmsGames from "./cmsGameReducer"
import prizeClaim from "./prizeClaimReducer"
import serviceCenter from "./serviceCenterReducer"
import whereToWatch from "./whereToWatchReducer"
import countyBreakdown from "./countyBreakdownReducer"
import adminAlert from "./adminAlertReducer"
import ticketScan from './ticketScanReducer'
import regions from "./regionsReducer"
import prizeClaimCenter from "./prizeClaimCenterReducer"
import winningNumbers from "./winningNumbersReducer"
import app from "./appReducer"
import messageResult from "./messageReducer"
import emergencyAlertReducer from "./emergencyAlertReducer"
import bacon from "./baconReducer"
import sso from "./tokenReducer"
import liveShow from "./liveShowReducer"
import i18nReducer from "./i18nReducer"
import logoReducer from "./logoReducer"
import stringsReducer from "./stringsReducer"
import topics from "./topicReducer"
import contactFormReducer from "./contactReducer"
import loadingReducer from "./loadingReducer"
import tickersReducer from "./tickersReducer";
import integrity from "./integrityReducer"

const reducers = {
  app,
  games,
  marquees,
  scratchOffGamesMarquees,
  promotions,
  events,
  categories,
  secondChance,
  winners,
  currentWinner,
  scratchOffGame,
  scratchOffGameDetail,
  scratchOffGamesReport,
  cmsGames,
  prizeClaim,
  serviceCenter,
  whereToWatch,
  countyBreakdown,
  adminAlert,
  regions,
  prizeClaimCenter,
  winningNumbers,
  messageResult,
  emergencyAlertReducer,
  sso,
  ticketScan,
  bacon,
  liveShow,
  i18nReducer,
  integrity,
  logo: logoReducer,
  strings: stringsReducer,
  topics,
  contactFormReducer,
  loading: loadingReducer,
  tickers: tickersReducer,
}

export default combineReducers(reducers)
