import axios from "axios"
import {
  BASIC_PAGE_IDS_REQUEST,
  BASIC_PAGE_IDS_SUCCESS,
  BASIC_PAGE_IDS_FAILURE,
  PAGE_CONTENT_REQUEST,
  PAGE_CONTENT_SUCCESS,
  PAGE_CONTENT_FAILURE,
  FOOTER_LINKS_REQUEST,
  FOOTER_LINKS_SUCCESS,
  FOOTER_LINKS_FAILURE,
  FOOTER_SOCIALS_REQUEST,
  FOOTER_SOCIALS_SUCCESS,
  FOOTER_SOCIALS_FAILURE,
  PAGE_DETAIL_CONTENT_REQUEST,
  PAGE_DETAIL_CONTENT_SUCCESS,
  PAGE_DETAIL_CONTENT_FAILURE,
  DYNAMICPAGE_CONTENT_REQUEST,
  DYNAMICPAGE_CONTENT_SUCCESS,
  DYNAMICPAGE_CONTENT_FAILURE,
  BASIC_PAGE_REQUEST,
  BASIC_PAGE_SUCCESS,
  BASIC_PAGE_FAILURE,
  EMAIL_SUBSCRIBE_REQUEST,
  EMAIL_SUBSCRIBE_SUCCESS,
  EMAIL_SUBSCRIBE_FAILURE,
  FOOTER_COPY_REQUEST,
  FOOTER_COPY_SUCCESS,
  FOOTER_COPY_FAILURE,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTION_FAILURE,
  FETCH_UPDATE_TOPIC_FAILURE,
  FETCH_UPDATE_TOPIC_SUCCESS,
  TPOIC_UPDATE_REQUEST,
  LOADING,
  LOADED,
  LOADING_FAILURE,
} from "../constants"
import { GATSBY_API_BASE_URL } from "../../config/config"
import { isLoadingOrLoaded, dispatchWithLoading } from "../helpers/loading"

const STORAGE_BASIC_PAGE_IDS = "basic_page_ids"
const STORAGE_PAGE_CONTENTS = "page_contents"
const STORAGE_DYNAMICPAGE_CONTENTS = "dynamicpage_contents"

// Function to return the language path
export const getLanguagePath = (currentLanguageValue = "") => {
  switch (currentLanguageValue) {
    case 'en':
      return '';
    case 'zh':
      return '/zh-hans';
    default:
      return `/${currentLanguageValue}`;
  }
};

// Get the language path for Gatsby URLS
export function getGatsbyLanguagePath(currentLanguageValue = '') {
  switch (currentLanguageValue) {
    case 'en':
      return '';
    default:
      return `/${currentLanguageValue}`;
  }
}


export const loadBasicPageIds = () => {
  const storedObject = localStorage.getItem(STORAGE_BASIC_PAGE_IDS)

  if (!navigator.onLine) {
    return dispatch => {
      dispatch({
        type: BASIC_PAGE_IDS_SUCCESS,
        payload: storedObject ? JSON.parse(storedObject) : {},
      })
    }
  }

  return dispatch => {
    dispatch({ type: BASIC_PAGE_IDS_REQUEST })

    axios
      .get(`${GATSBY_API_BASE_URL}/api/basic_page_ids`)
      .then(result => {
        localStorage.setItem(
          STORAGE_BASIC_PAGE_IDS,
          JSON.stringify(result.data)
        )
        dispatch({
          type: BASIC_PAGE_IDS_SUCCESS,
          payload: result.data,
        })
      })
      .catch(err => {
        if (storedObject) {
          return dispatch => {
            dispatch({
              type: BASIC_PAGE_IDS_SUCCESS,
              payload: JSON.parse(storedObject),
            })
          }
        }
        dispatch({
          type: BASIC_PAGE_IDS_FAILURE,
          payload: err,
        })
      })
  }
}

export const loadPageContent = () => {
  const storedObject = localStorage.getItem(STORAGE_PAGE_CONTENTS)

  if (!navigator.onLine) {
    return dispatch => {
      dispatch({
        type: PAGE_CONTENT_SUCCESS,
        payload: storedObject ? JSON.parse(storedObject) : {},
      })
    }
  }

  return (dispatch, getState) => {
    const languagePath = getLanguagePath(getState().i18nReducer.language);
    dispatch({ type: PAGE_CONTENT_REQUEST })

    axios
      .get(`${GATSBY_API_BASE_URL}${languagePath}/api/page?_format=json`)
      .then(result => {
        localStorage.setItem(STORAGE_PAGE_CONTENTS, JSON.stringify(result.data))
        dispatch({
          type: PAGE_CONTENT_SUCCESS,
          payload: result.data,
        })
      })
      .catch(err => {
        if (storedObject) {
          return dispatch({
            type: PAGE_CONTENT_SUCCESS,
            payload: JSON.parse(storedObject),
          })
        }
        dispatch({
          type: PAGE_CONTENT_FAILURE,
          payload: err,
        })
      })
  }
}

export const loadPageDetailContent = (id, page, language) => {
  const storedObject = localStorage.getItem(`page_content_${id}`)

  if (!navigator.onLine) {
    return dispatch => {
      dispatch({
        type: PAGE_DETAIL_CONTENT_SUCCESS,
        payload: {
          data: storedObject ? JSON.parse(storedObject) : {},
          page,
        },
      })
    }
  }

  return (dispatch, getState) => {
    const languagePath = getLanguagePath(language);
    dispatch({ type: PAGE_DETAIL_CONTENT_REQUEST })

    axios
      .get(`${GATSBY_API_BASE_URL}${languagePath}/api/page/${id}?_format=json`)
      .then(result => {
        localStorage.setItem(`page_content_${id}`, JSON.stringify(result.data))
        dispatch({
          type: PAGE_DETAIL_CONTENT_SUCCESS,
          payload: {
            data: result.data,
            page,
          },
        })
      })
      .catch(err => {
        if (storedObject) {
          return dispatch({
            type: PAGE_DETAIL_CONTENT_SUCCESS,
            payload: {
              data: JSON.parse(storedObject),
              page,
            },
          })
        }
        dispatch({
          type: PAGE_DETAIL_CONTENT_FAILURE,
          payload: err,
        })
      })
  }
}



export const loadDynamicPageContent = () => {
  const storedObject = localStorage.getItem(STORAGE_DYNAMICPAGE_CONTENTS)

  if (!navigator.onLine) {
    return dispatch => {
      dispatch({
        type: DYNAMICPAGE_CONTENT_SUCCESS,
        payload: storedObject ? JSON.parse(storedObject) : {},
      })
    }
  }

  return (dispatch, getState) => {
    const languagePath = getLanguagePath(getState().i18nReducer.language);
    dispatch({ type: DYNAMICPAGE_CONTENT_REQUEST })

    axios
      .get(`${GATSBY_API_BASE_URL}${languagePath}/api/dynamic_landing_page?_format=json`)
      .then(result => {
        localStorage.setItem(STORAGE_DYNAMICPAGE_CONTENTS, JSON.stringify(result.data))
        dispatch({
          type: DYNAMICPAGE_CONTENT_SUCCESS,
          payload: result.data,
        })
      })
      .catch(err => {
        if (storedObject) {
          return dispatch({
            type: DYNAMICPAGE_CONTENT_SUCCESS,
            payload: JSON.parse(storedObject),
          })
        }
        dispatch({
          type: DYNAMICPAGE_CONTENT_FAILURE,
          payload: err,
        })
      })
  }
}



export const loadFooterLinks = () => (dispatch, getState) => {
  const state = getState();
  const languagePath = getLanguagePath(state.i18nReducer.language);
  if (isLoadingOrLoaded(state, [FOOTER_LINKS_REQUEST, FOOTER_LINKS_SUCCESS])) return;
  dispatchWithLoading(dispatch, { add: [FOOTER_LINKS_REQUEST] }, LOADING, null, state);

  axios
    .get(`${GATSBY_API_BASE_URL}${languagePath}/api/menu/footer`)
    .then(result => {
      dispatchWithLoading(dispatch, { add: [FOOTER_LINKS_SUCCESS], remove: [FOOTER_LINKS_REQUEST] }, LOADED, result.data, state);
    })
    .catch(err => {
      dispatchWithLoading(dispatch, { add: [FOOTER_LINKS_FAILURE], remove: [FOOTER_LINKS_REQUEST] }, LOADING_FAILURE, err, state);
    })
}

export const loadFooterSocials = () => (dispatch, getState) => {
  const state = getState();
  if (isLoadingOrLoaded(state, [FOOTER_SOCIALS_REQUEST, FOOTER_SOCIALS_SUCCESS])) return;
  dispatchWithLoading(dispatch, { add: [FOOTER_SOCIALS_REQUEST] }, LOADING, null, state);

  axios
    .get(`${GATSBY_API_BASE_URL}/api/menu/social-media-links`)
    .then(result => {
      dispatchWithLoading(dispatch, { add: [FOOTER_SOCIALS_SUCCESS], remove: [FOOTER_SOCIALS_REQUEST] }, LOADED, result.data, state);
    })
    .catch(err => {
      dispatchWithLoading(dispatch, { add: [FOOTER_SOCIALS_FAILURE], remove: [FOOTER_SOCIALS_REQUEST] }, LOADING_FAILURE, err, state);
    })
}

export const loadPage = (pageName, pageNumber) => (dispatch, getState) => {
  const state = getState();
  const languagePath = getLanguagePath(state.i18nReducer.language);
  dispatch({ type: BASIC_PAGE_REQUEST, name: pageName })

  axios
    .get(`${GATSBY_API_BASE_URL}${languagePath}/api/${pageName}?_format=json&page=${pageNumber || 0}`)
    .then(result => {
      dispatch({
        type: BASIC_PAGE_SUCCESS,
        name: pageName,
        page: pageNumber,
        data: result.data
      })
    })
    .catch(err => {
      dispatch({
        type: BASIC_PAGE_FAILURE,
        name: pageName,
        payload: err,
      })
    })
}

export function loadPageFromAlias(pageName, pageNumber = null, language = '') {
  return function (dispatch) {
    if (!pageName) return; // Prevent empty page name
    const url = `${GATSBY_API_BASE_URL}${language && language !== 'en' ? `/${language}${language === 'zh' ? '-hans' : ''}` : ''}/api/v2/page_from_alias?_format=json&page=${pageNumber || 0}&alias=${pageName}`;
    dispatch({ type: BASIC_PAGE_REQUEST, name: pageName });
    axios
      .get(url)
      .then(result => {
        dispatch({
          type: BASIC_PAGE_SUCCESS,
          name: pageName,
          page: pageNumber,
          data: result.data
        })
      })
      .catch(err => {
        dispatch({
          // We have to send a success here in order to detect if we should send a 404
          type: BASIC_PAGE_SUCCESS,
          name: pageName,
          page: pageNumber,
          data: { error: true },
          payload: err,
        })
      });
  }
}

export const emailSubscribe = (registerUser = true, email, topic, inPopup = false) => dispatch => {
  dispatch({ type: EMAIL_SUBSCRIBE_REQUEST, email })
  axios.
    post("/emailapi", {
      'email': email,
      'topic': topic,
      'inPopup': inPopup,
      'registerUser': registerUser
    })
    .then(result => {
      dispatch({
        type: EMAIL_SUBSCRIBE_SUCCESS,
        data: result,
      })
    })
    .catch(err => {
      dispatch({
        type: EMAIL_SUBSCRIBE_FAILURE,
        err: err.response,
      })
    })
}

export const addTopics = (email, topic, code) => dispatch => {
  dispatch({ type: TPOIC_UPDATE_REQUEST, email })
  axios.
    post("/emailapi/addTopics", {
      'email': email,
      'topic': topic
    }, {
      headers: {
        'X-JWT-TOKEN': code,
      },
    })
    .then(result => {
      dispatch({
        type: FETCH_UPDATE_TOPIC_SUCCESS,
        data: result,
      })
    })
    .catch(err => {
      dispatch({
        type: FETCH_UPDATE_TOPIC_FAILURE,
        err: err.response,
      })
    })
}

export const removeTopics = (email, topic, code) => dispatch => {
  dispatch({ type: TPOIC_UPDATE_REQUEST, email })
  axios.
    delete("/emailapi/removeTopics", {
      data: {
        'email': email,
        'topic': topic
      },
      headers: {
        'X-JWT-TOKEN': code,
      },
    })
    .then(result => {
      dispatch({
        type: FETCH_UPDATE_TOPIC_SUCCESS,
        data: result,
      })
    })
    .catch(err => {
      dispatch({
        type: FETCH_UPDATE_TOPIC_FAILURE,
        err: err.response,
      })
    })
}
/**
 * isqd - 'y' / 'n'
 */
export const getRetailersByLatLng = (lat, lng, page, isqd) => {
  const radius = 10

  let url = `${GATSBY_API_BASE_URL}/api/retailers?_format=json`

  //please note, there is some issue with how google is doing lat lng vs how we are. So the values need to be switched in this call.
  return axios
    .get(
      `${url}&latlng[value]=${radius}&latlng[source_configuration][origin][lat]=${lng}&latlng[source_configuration][origin][lon]=${lat}&page=${page}${isqd === 'y' ? '&isqd=y' : ''}`
    )
    .then(results => results.data)
}

export const loadFooterCopy = () => (dispatch, getState) => {
  const state = getState();
  if (isLoadingOrLoaded(state, [FOOTER_COPY_REQUEST, FOOTER_COPY_SUCCESS])) return;
  const languagePath = getLanguagePath(state.i18nReducer.language);
  dispatchWithLoading(dispatch, { add: [FOOTER_COPY_REQUEST] }, LOADING, null, state);

  axios
    .get(`${GATSBY_API_BASE_URL}${languagePath}/api/footer/entries?_format=json`)
    .then(result => {
      dispatchWithLoading(dispatch, { add: [FOOTER_COPY_SUCCESS], remove: [FOOTER_COPY_REQUEST] }, LOADED, result.data, state);
    })
    .catch(err => {
      dispatchWithLoading(dispatch, { add: [FOOTER_COPY_FAILURE], remove: [FOOTER_COPY_REQUEST] }, LOADING_FAILURE, err, state);
    })
}
