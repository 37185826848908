import {
  LOAD_EMERGENCY_ALERT_REQUEST,
  LOAD_EMERGENCY_ALERT_SUCCESS,
  LOAD_EMERGENCY_ALERT_FAILURE,
} from "../constants"

const INITIAL_STATE = {
  alerts: {
    emergency: {
      // title: "",
      // link: "",
      // link_label: "",
      // update_timestamp: "",
      // update_timestamp: "",
      // description: "",
      // do_redirect: false,
    },
    global_notification: {
    },
  },
  alertsFetching: false,
}

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_EMERGENCY_ALERT_REQUEST: {
      return {
        ...state,
        alertsFetching: true,
      }
    }
    case LOAD_EMERGENCY_ALERT_SUCCESS: {
      return {
        ...state,
        alerts: action.payload,
        alertsFetching: false,
      }
    }
    case LOAD_EMERGENCY_ALERT_FAILURE: {
      return {
        ...state,
        alerts: {},
        alertsFetching: false,
      }
    }
    default:
      return state
  }
}
