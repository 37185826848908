import { SCRATCH_OFF_GAME_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.scratchOffGameDetail

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SCRATCH_OFF_GAME_SUCCESS:
      return action.payload
    default:
      return state
  }
}
