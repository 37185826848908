import { STRINGS_REQUEST, STRINGS_SUCCESS, STRINGS_FAILURE } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.stringByName
const stringsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case STRINGS_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case STRINGS_SUCCESS:
        return {
          ...state,
          strings: action.payload,
          isLoading: false,
          error: null
        };
      case STRINGS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default stringsReducer;
  