import { LOAD_SERVICE_CENTER_SUCCESS } from '../constants'

const INITIAL_STATE = [];

export default function(state = INITIAL_STATE, action = {}) {
	switch(action.type) {
		case LOAD_SERVICE_CENTER_SUCCESS: {
			return action.payload;
		}
		default:
			return state;
	}
}
