import { SECOND_CHANCE_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.secondChance

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SECOND_CHANCE_SUCCESS: {
      return [...action.payload]
    }
    default:
      return state
  }
}
