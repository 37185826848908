import { LOAD_CMS_GAMES_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.cmsGames

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_CMS_GAMES_SUCCESS:
      return [...action.payload]
    default:
      return state
  }
}
