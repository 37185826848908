import { LOAD_GAMES_SUCCESS, LOAD_LATESTQUICKDRAW_SUCCESS } from '../constants'

const INITIAL_STATE = {
	lotto: null,
	powerball: null,
	take5: null,
	megamillions: null,
	cash4life: null,
	pick10: null,
	numbers: null,
	win4: null,
	quickdraw: null,
	latestQuickdraw: null,
};

export default function(state = INITIAL_STATE, action = {}) {
	switch(action.type) {
		case LOAD_GAMES_SUCCESS: {
			return {
				...state,
				...action.payload,
			}
		}
		case LOAD_LATESTQUICKDRAW_SUCCESS: {
			return {
				...state,
				latestQuickdraw: action.payload,
			}
		}
		default:
			return state;
	}
}
