import { STRINGS_REQUEST, STRINGS_SUCCESS, STRINGS_FAILURE } from "../constants"

// logoReducer.js
const initialState = {
    logoPath: '',
  };

  const logoReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LOGO_PATH':
        return {
          ...state,
          logoPath: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default logoReducer;
  