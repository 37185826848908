import { SCRATCH_OFF_GAMES_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.scratchOffGame

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SCRATCH_OFF_GAMES_SUCCESS: {
      return {
        ...state,
        allGames: action.payload,
        games: state.games.length ? state.games : action.payload,
        pager: action.pager
      }
    }
    default:
      return state
  }
}
