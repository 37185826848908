import { ADMIN_ALERT_REQUEST, ADMIN_ALERT_SUCCESS, ADMIN_ALERT_TOGGLE } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.adminAlert

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ADMIN_ALERT_REQUEST:
      return { ...state, ...action.payload };
    case ADMIN_ALERT_SUCCESS:
      return { ...state, ...action.payload };
    case ADMIN_ALERT_TOGGLE:
      return { ...state, opened: !state.opened };
    default:
      return state;
  }
}
