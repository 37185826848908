import { REGIONS_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.regions

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case REGIONS_SUCCESS: {
      return [
        ...action.payload.data
      ]
    }
    default:
      return state
  }
}
