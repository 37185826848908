import { LOAD_TOPIC_REQUEST, LOAD_TOPIC_SUCCESS, LOAD_TOPIC_FAILURE, SET_TOPIC_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.topics

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOAD_TOPIC_REQUEST:
      return { ...state, loading: true };
    case LOAD_TOPIC_SUCCESS:
      return { ...state, topics: action.payload, loading: false, error: null };
    case LOAD_TOPIC_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SET_TOPIC_SUCCESS:
      return { ...state, currentTopic: action.payload };
    default:
      return state;
  }
}