import { COUNTY_BREAKDOWN_SUCCESS } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.countyBreakdown

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case COUNTY_BREAKDOWN_SUCCESS: {
      return [...action.payload]
    }
    default:
      return state
  }
}
