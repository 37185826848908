import {
  SWEEPS_SUCCESS,
  SWEEPS_FAILURE
} from "../constants"

import initialState from "../initialState"

const INITIAL_STATE = initialState.bacon

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SWEEPS_SUCCESS:
      return { ...state, ...action.payload }
    case SWEEPS_FAILURE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
