import axios from "axios"
import {
  LOAD_GAMES_REQUEST,
  LOAD_GAMES_SUCCESS,
  LOAD_GAMES_FAILURE,
  LOAD_LATESTQUICKDRAW_REQUEST,
  LOAD_LATESTQUICKDRAW_SUCCESS,
  LOAD_LATESTQUICKDRAW_FAILURE,
  MARQUEE_REQUEST,
  MARQUEE_SUCCESS,
  MARQUEE_FAILURE,
  SCRATCH_OFF_GAMES_MARQUEE_REQUEST,
  SCRATCH_OFF_GAMES_MARQUEE_SUCCESS,
  SCRATCH_OFF_GAMES_MARQUEE_FAILURE,
  LOADING,
  LOADED,
  LOADING_FAILURE,
} from "../constants"
import { GATSBY_API_BASE_URL, GATSBY_API_DRIVEN_URL } from "../../config/config"
import { isLoadingOrLoaded, dispatchWithLoading } from "../helpers/loading"

export const loadGames = () => {
  return (dispatch, getState) => {
    const state = getState();
    // We may later want to update game data, so don't include LOAD_GAMES_SUCCESS here,
    // But in the future we might want to refactor to include LOAD_GAMES_SUCCESS.
    if (isLoadingOrLoaded(state, [LOAD_GAMES_REQUEST])) return;
    dispatchWithLoading(dispatch, { add: [LOAD_GAMES_REQUEST] }, LOADING);

    // Setting up the primary API request with a timeout of 10 seconds.
    const primaryApiRequest = axios.get(`${GATSBY_API_DRIVEN_URL}/games/all/draws`, { timeout: 10000 });

    primaryApiRequest
      .then(result => {
        const { ...rest } = result.data.data
        dispatchWithLoading(dispatch, { add: [LOAD_GAMES_SUCCESS], remove: [LOAD_GAMES_REQUEST] }, LOADED, rest);
      })
      .catch(err => {
        // Handle error for the primary API request.
        dispatchWithLoading(dispatch, { add: [LOAD_GAMES_FAILURE], remove: [] }, LOADING_FAILURE, err);

        // If the primary API fails, attempt to fetch from the fallback API.
        const fallbackApiRequest = axios.get(`${GATSBY_API_BASE_URL}/api/all/draw_games_fallback?_format=json`);

        fallbackApiRequest
          .then(fallbackResult => {
            const { ...restFallback } = fallbackResult.data.data
            dispatchWithLoading(dispatch, { add: [LOAD_GAMES_SUCCESS], remove: [LOAD_GAMES_REQUEST] }, LOADED, restFallback);
          })
          .catch(fallbackErr => {
            // Handle error for the fallback API request.
            dispatchWithLoading(dispatch, { add: [LOAD_GAMES_FAILURE], remove: [LOAD_GAMES_REQUEST] }, LOADING_FAILURE, fallbackErr);
          })
      })
  }
}

export const marqueeRequest = () => (dispatch, getState) => {
  const state = getState();
  if (isLoadingOrLoaded(state, [MARQUEE_REQUEST, MARQUEE_SUCCESS])) return;
  dispatchWithLoading(dispatch, { add: [MARQUEE_REQUEST] }, LOADING);
  return axios
    .get(`${GATSBY_API_BASE_URL}/api/marquee?_format=json`)
    .then(result => {
      dispatchWithLoading(dispatch, { add: [MARQUEE_SUCCESS], remove: [MARQUEE_REQUEST] }, LOADED, result.data);
    })
    .catch(err => {
      dispatchWithLoading(dispatch, { add: [MARQUEE_FAILURE], remove: [MARQUEE_REQUEST] }, LOADING_FAILURE, err);
    })
}

export const scratchOffGamesMarqueeRequest = () => (dispatch, getState) => {
  const state = getState();
  if (isLoadingOrLoaded(state, [SCRATCH_OFF_GAMES_MARQUEE_REQUEST, SCRATCH_OFF_GAMES_MARQUEE_SUCCESS])) return;
  dispatchWithLoading(dispatch, { add: [SCRATCH_OFF_GAMES_MARQUEE_REQUEST] }, LOADING);
  return axios
    .get(`${GATSBY_API_BASE_URL}/api/scratch-off-games-marquee?_format=json`)
    .then(result => {
      dispatchWithLoading(dispatch, { add: [SCRATCH_OFF_GAMES_MARQUEE_SUCCESS], remove: [SCRATCH_OFF_GAMES_MARQUEE_REQUEST] }, LOADED, result.data);
    })
    .catch(err => {
      dispatchWithLoading(dispatch, { add: [SCRATCH_OFF_GAMES_MARQUEE_FAILURE], remove: [SCRATCH_OFF_GAMES_MARQUEE_REQUEST] }, LOADING_FAILURE, err);
    })
}

export const loadQuickdraw = () => (dispatch, getState) => {
  const state = getState();
  if (isLoadingOrLoaded(state, [LOAD_LATESTQUICKDRAW_REQUEST, LOAD_LATESTQUICKDRAW_SUCCESS])) return;
  dispatchWithLoading(dispatch, { add: [LOAD_LATESTQUICKDRAW_REQUEST] }, LOADING);
  return axios
    .get(`${GATSBY_API_DRIVEN_URL}/games/quickdraw/draws`)
    .then(result => {
      const { status, statusCode, ...rest } = result.data.data;
      dispatchWithLoading(dispatch, { add: [LOAD_LATESTQUICKDRAW_SUCCESS], remove: [LOAD_LATESTQUICKDRAW_REQUEST] }, LOADED, rest);
    })
    .catch(err => {
      dispatchWithLoading(dispatch, { add: [LOAD_LATESTQUICKDRAW_FAILURE], remove: [LOAD_LATESTQUICKDRAW_REQUEST] }, LOADING_FAILURE, err);
    })
}